import React, {PropsWithChildren, useEffect, useState} from 'react';
import Arrow from '../../assets/modal-comics/arrow.webp'
import Book from '../../assets/modal-comics/comics.webp'
import './modalComics.scss'
import {baseUrl} from "../../constants/constants";

interface IModalComics{
    active: boolean,
    close: () => void
}

interface IComics {
    file_url: string
}
const ModalComics = ({active, close}: IModalComics) => {
    const [metrikaCounted, setMetrikaCounted] = React.useState(false)
    const [manual, setManual] = useState<IComics>()
    const handleView = () => {
        if (!metrikaCounted) {
            setMetrikaCounted(true)
            // @ts-ignore
            ym(100105951,'reachGoal','metodichki-sbornik')
        }
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'unset'

        }
    }, []);

    useEffect(() => {
        fetch(`${baseUrl}manuals/27`)
            .then((res) => res.json())
            .then((data) => {
                setManual(data)
            })
    }, []);

    return (
        <>
            {active ?
                <section className={'comics-modal'} onClick={close}>
                    <div className={'comics-modal__body'}>
                        <p className={'comics_modal__body-close'} onClick={close}>&times;</p>
                        <div className={'comics-modal__left'}>
                            <h2 className={'comics-modal__left-title'}>СОЗДАЛИ КОМИКС ДЛЯ ДЕТЕЙ СРЕДНИХ КЛАССОВ</h2>
                            <p className={'comics-modal__left-text'}>
                                <span className={'comics-modal__left-text-up'}>Специалисты НЦПТИ по профилактике разработали
                                    комикс, основанный </span><br className={'br-first'}/>
                                <span className={'comics-modal__left-text-down'}>на реальных кейсах вербовки школьников<br className={'br-second'}/> в противоправную
                                    деятельность</span>
                            </p>
                            <div className={'comics-modal__left-white'}>
                                <p>Скачивайте комикс для печати на стенде и для распространения в электронном виде</p>
                            </div>
                            {manual && (
                            <div className={'comics-modal__left-buttons'}>
                            <a href={`${baseUrl}${manual.file_url}`}>
                                        <button
                                            className={'comics-modal__left-buttons-desktop'}
                                            onClick={handleView}
                                        >
                                            Скачать
                                        </button>
                            </a>
                            <a href={`${baseUrl}${manual.file_url}`}>
                                <button
                                    className={'comics-modal__left-buttons-mobile'}
                                    onClick={handleView}
                                >
                                    <svg width="31" height="35" viewBox="0 0 31 35" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.2963 1.98633C17.2963 1.13748 16.6081 0.449355 15.7593 0.449355C14.9104 0.449355 14.2223 1.13748 14.2223 1.98633L17.2963 1.98633ZM14.6725 26.4379C15.2727 27.0381 16.2459 27.0381 16.8461 26.4379L26.6273 16.6567C27.2275 16.0565 27.2275 15.0833 26.6273 14.4831C26.0271 13.8828 25.0539 13.8828 24.4537 14.4831L15.7593 23.1775L7.06485 14.4831C6.46462 13.8828 5.49146 13.8828 4.89124 14.4831C4.29101 15.0833 4.29101 16.0565 4.89124 16.6567L14.6725 26.4379ZM14.2223 1.98633L14.2223 25.3511L17.2963 25.3511L17.2963 1.98633L14.2223 1.98633Z"
                                            fill="#5E7BE9"/>
                                        <path
                                            d="M2.08203 21.1357V28.4305C2.08203 30.948 4.12287 32.9888 6.64037 32.9888H24.876C27.3935 32.9888 29.4344 30.948 29.4344 28.4305V21.1357"
                                            stroke="#5E7BE9" strokeWidth="3.07232" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </a>
                            <img
                                src={Arrow}
                                alt="стрелочка"
                                className={'white-arrow'}
                            />
                        </div>
                                )}
                    </div>
                    <div className={'comics-modal__right'}>
                        <img src={Book} alt="" className={'comics-modal__right-img'}/>
                    </div>
                </div>
                </section>
                :
                <></>
            }
        </>
    );
};

export default ModalComics;